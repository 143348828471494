body {
  margin: 0;
  background: #FFFFFF;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat';
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: local('Montserrat'), url(./assets/fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  letter-spacing: 0.02em;
  src: local('Montserrat'), url(./assets/fonts/Montserrat-SemiBold.ttf) format('truetype');
}